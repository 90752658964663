@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply w-full h-full;
}

#root {
  @apply w-full h-full;

  /* datepicker 등 라이브러리 css 커스텀할 때 사용 */
  --primary-da-blue: #2548c4;
  --button-primary-normal: #4f74f9;
  --state-red: #e48686;
  --primary-white: #fff;
  --state-blue: #7b8cc8;
  --state-blue-bg: #f2f5ff;
}

* {
  font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;

  @apply text-gray-22;

  &::-webkit-scrollbar {
    background: transparent;
    /* width: 12px; */
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    /* height: 100px; */
    background: #d9d9d9;
    width: 4px;
    /* background-clip: padding-box; */
    /* border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent; */
  }
}

input {
  @apply border border-gray-99;
  @apply focus:outline-none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #444;
  -webkit-text-size: 0.875rem;
  font-size: 0.875rem;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

button {
  @apply whitespace-nowrap;
  @apply cursor-pointer;
}
