.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  .gradient_loader {
    width: 0.6rem;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
  }
}

@keyframes l5 {
  0% {
    background: #8cccd5;
    box-shadow: 0.95rem 0 #8cccd5, -0.95rem 0 #d9f0f4;
  }
  33% {
    background: #d9f0f4;
    box-shadow: 0.95rem 0 #8cccd5, -0.95rem 0 #d9f0f4;
  }
  66% {
    background: #d9f0f4;
    box-shadow: 0.95rem 0 #d9f0f4, -0.95rem 0 #8cccd5;
  }
  100% {
    background: #8cccd5;
    box-shadow: 0.95rem 0 #d9f0f4, -0.95rem 0 #8cccd5;
  }
}
